<template>
  <div class="container">

    <div class="graduate-work-temp mt-4 mb-4">
      <h4 class="text-center mb-4">Дипломная / Научная работа</h4>

      <DataTable :value="graduateWorkTemp.graduateWorkThemes" :paginator="true" :rows="10"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                 stripedRows responsiveLayout="scroll">

        <Column field="theme_name_kz" header="Название темы на казахском"></Column>
        <Column field="theme_name_ru" header="Название темы на русском"></Column>
        <Column field="theme_name_en" header="Название темы на английском"></Column>
        <Column field="education_program_code" header="Код ОП"></Column>
        <Column field="study_level_name" header="Уровень обучения"></Column>
        <Column field="academic_year_name" header="Учебный год"></Column>
        <Column header="Автор темы">
          <template #body="{data}">
            {{ data.lastname }} {{ data.firstname }}
          </template>
        </Column>
        <Column header="Действия">

          <template #body="{data}">
            <Button icon="pi-user-plus" @click="openChooseGraduateWorkCountDialog(data.id)"/>
          </template>


        </Column>


      </DataTable>


      <Dialog v-model:visible="chooseGraduateWorkCountDisplay" :style="{width: '50vw'}">
        <template #header>
          <h5>Выбор количество</h5>
        </template>

        <div style="height: 200px" class="col-md-12 mt-4">
          <div class="form-group row mt-4">
            <label for="count_of_students" class="col-md-3 col-form-label">Количество студентов</label>
            <div class="col-md-9" id="count_of_students">
              <InputNumber v-model="value" :min="0" :max="100"/>
            </div>
          </div>

        </div>

        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeChooseStudentDialog"/>
          <Button label="Сохранить" icon="pi pi-check" @click="submitSelectionTheme"/>
        </template>
      </Dialog>

    </div>
  </div>
</template>


<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "SelectionSupervisors",
  components: {},
  data() {
    return {
      selected_pps: 0,
      chooseGraduateWorkCountDisplay: false,
      value: 0,
    }
  },
  computed: {
    ...mapState('selectionSupervisors', ['department_pps']),
    ...mapState('graduateWorkTemp', ['graduateWorkTemp']),
  },

  methods: {
    ...mapActions('selectionSupervisors', ['GET_PPS_BY_DEPARTMENT_ID', 'GET_STUDY_LEVELS']),
    ...mapActions('graduateWorkTemp', ['GET_GRADUATE_WORK_THEMES']),

    async openChooseGraduateWorkCountDialog(graduate_work_theme_id) {
      this.chooseGraduateWorkCountDisplay = true
    }
  },

  async mounted() {
    this.GET_GRADUATE_WORK_THEMES()
    this.GET_PPS_BY_DEPARTMENT_ID()
    this.GET_STUDY_LEVELS()
  }
}
</script>

<style scoped>
</style>